// Addition function
export function addition(a, b) {
  console.log(a + b);
}

// export function modify_data() {
//   fetch('../records.json')
//     .then((response) => response.json())
//     .then((data) => console.log(data));
// }

// export const siteData = fetch('../records.json')
  export const siteData = fetch('https://www.destemma.org/records.json')
  .then((response) => response.json())
  .then((data) => {
    return data;
  });

// DOM ELEMENTS
export const dom = {
  jsCover: document.getElementById('jsCover'),
  jsGdpr: document.getElementById('js__gdpr'),
  gdpr: document.querySelector('.gdpr'),
  gdprRemove: document.querySelector('.gdprRemove'),
  jsCloseGdpr: document.getElementById('js__close__gdpr'),
  refreshPage: document.getElementById('refresh'),
  bsHouseDoor: document.getElementById('bsHouseDoor'),
  expandMenu: document.querySelector('.expandMenu'),
  dockWrapper: document.querySelector('.dockWrapper'),
  bsFilter: document.querySelector('.bsFilter'),
  bsGrid: document.querySelector('.bsGrid'),
  bsHelp: document.querySelector('.bsHelp'),
  appContainer: document.getElementById('appContainer'),
  appBody: document.getElementById('appBody'),
  backToTopBtn: document.getElementById('backToTop'),
  showMenuItems: document.querySelector('.showMenuItems'),
  informationFlash: document.querySelector('.informationFlash'),
  navbar: document.querySelector('.navbar'),
  masterHead: document.querySelector('.masterHead'),
  getCurrentDate: document.querySelector('.getCurrentDate'),
  advert: document.querySelector('.advert'),
  advertMobile: document.querySelector('.advertMobile'),
  alertMessages: document.querySelector('.alertMessages'),
  bsSettings: document.querySelector('.bsSettings'),
  bsFeedback: document.querySelector('.bsFeedback'),
  displayMenu: document.getElementById('displayMenu'),
  classicTheme: document.getElementById('classicTheme'),
  darkTheme: document.getElementById('darkTheme'),
};
